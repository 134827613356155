import React from "react";
import StepPagination from "../StepPagination";
import {FormControl, FormGroup, TextField} from "@material-ui/core";
import InputMask from "react-input-mask";
import HelperText from "../HelperText";

const RefitLeadBirthDateStep = ({values, nextStep, prevStep, errors, validation, handleChange, updateErrors}) => {

    return(
        <div>
            <FormGroup className={'birthdate-step-fields'}>
                <FormControl>
                    <label className={'field-title'}>DATA DE NASCIMENTO</label>
                    <br></br>
                    <InputMask
                        mask="99/99/9999"
                        maskChar={''}
                        onChange={handleChange('birthdate')}
                        value={values.birthdate}
                    >
                        {() => <TextField
                            error={errors.birthdate}
                            type='tel'
                            helperText={errors.birthdate}
                        />}
                    </InputMask>
                </FormControl>
                <HelperText text={`Com a sua idade, podemos avaliar se você está apto a entrar no programa ${getReformProgramName()}`}/>
            </FormGroup>
            <StepPagination prevStep={prevStep} nextStep={nextStep} validation={validation} updateErrors={updateErrors} values={values}/>
        </div>
    )
}

const getReformProgramName = () =>{
    const urlParams = new URLSearchParams(window.location.search);
    const form = urlParams.get('form')
    return form !== 'gerdau' ? 'de reforma Casa Nova Vivenda!' : 'Reforma que Transforma'
}

export default RefitLeadBirthDateStep;
