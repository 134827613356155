import { FormControl, FormGroup, FormHelperText } from "@material-ui/core";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";
import "../step.css";
import StepPagination from "../StepPagination";

const RefitLeadProductStep = ({
  values,
  nextStep,
  prevStep,
  errors,
  handleChange,
  validation,
  updateErrors,
}) => {
  return (
    <div>
      <FormGroup className={"name-step-fields"}>
        <FormControl error={errors.product}>
          <label className={"field-title"}>
            O QUE VOCÊ QUER FAZER NA SUA CASA?
          </label>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={"REFORMA"}
            name="radio-buttons-group"
            className={"product-select"}
            onChange={handleChange("product")}
          >
            <FormControlLabel
              value="REFORMA"
              control={<Radio />}
              label="QUERO REFORMAR A MINHA CASA"
            />
            <FormHelperText>
              Quero reformar um espaço ou alguns espaços da minha casa, para
              resolver problemas ou deixá-la mais bonita e confortável!
            </FormHelperText>
            <FormControlLabel
              value="CONSTRUCAO"
              control={<Radio />}
              label="QUERO CONSTRUIR A MINHA CASA"
            />
            <FormHelperText>
              Tenho um terreno e preciso começar a construção do zero!
            </FormHelperText>
            <FormControlLabel
              value="PROJETO"
              control={<Radio />}
              label="QUERO ALGUMAS IDEIAS DO QUE FAZER NA MINHA CASA"
            />
            <FormHelperText>
              Busco um arquiteto que me dê dicas ou faça um projeto para que eu
              mesmo corra atrás de executar minha reforma sozinho, sem a ajuda
              da Nova Vivenda.
            </FormHelperText>
          </RadioGroup>
        </FormControl>
      </FormGroup>
      <StepPagination
        prevStep={prevStep}
        nextStep={nextStep}
        validation={validation}
        updateErrors={updateErrors}
        values={values}
      />
    </div>
  );
};

export default RefitLeadProductStep;
