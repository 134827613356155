const getMessage = (name, form, reason, field) => {
  name = name.trim();
  const vivendaMessages = {
    elegivel: {
      title: `OBRIGADO ${name},`,
      message: "agora é só aguardar que a nossa equipe entrará em contato!",
      sendoff: "Até logo,",
    },
    renda: {
      title: `OLÁ ${name},`,
      message:
        "agradecemos seu interesse em reformar com a Nova Vivenda, mas nesse momento a gente não consegue te ajudar. Temos alguns critérios que são determinantes para que possamos seguir com o seu cadastro, por exemplo: possuir um CPF ativo, renda salarial igual ou maior que R$937,00, ter interesse em reformar sua casa com a Nova Vivenda, e não possuir mais que 80 anos.",
      sendoff: "Tenha um ótimo dia,",
    },
    regiao: {
      title: `OLÁ ${name},`,
      message:
        "verifiquei aqui e nesse momento a gente não consegue te ajudar por não haver um Negócio da Causa que atenda o seu território. Mas não esquenta a cabeça, assim que chegarmos aí, e não vai demorar muito, nós entraremos em contato, combinado?",
      sendoff: "Conte com a gente,",
    },
    idade: {
      title: `OLÁ ${name},`,
      message:
        "foi por pouco! Verifiquei aqui e nesse momento a gente ainda não consegue te ajudar em função de você não possuir a idade mínima necessária, maior que 21 anos. Mas se houver alguém na sua casa com mais de 21 anos, pede para entrar em contato com a gente.",
      sendoff: "Conte com a gente,",
    },
    produto: {
      title: `OLÁ ${name},`,
      message:
        "agradecemos seu interesse em reformar com a Nova Vivenda, mas nesse momento a gente não consegue te ajudar. Temos alguns critérios que são determinantes para que possamos seguir com o seu cadastro, por exemplo: possuir um CPF ativo, renda salarial igual ou maior que R$937,00, ter interesse em reformar sua casa com a Nova Vivenda, e não possuir mais que 80 anos.",
      sendoff: "Tenha um ótimo dia,",
    },
  };

  const gerdauIneligibleMessage =
    "agradecemos seu interesse em reformar com o Reforma que Transforma, mas nesse momento a gente não consegue te ajudar. Temos alguns critérios que são determinantes para que possamos seguir com o seu cadastro. Por exemplo:";
  const criteriaList = [
    "<li>haver um negócio que atue na sua área;</li>",
    "<li>a sua cidade e bairro devem fazer parte dos doze territórios beneficiados pelo programa;</li>",
    "<li>possuir um CPF ativo;</li>",
    "<li>ter uma renda salarial familiar igual ou maior que R$ 937,00;</li>",
    "<li>possuir mais que 21 anos ou menos que 80 anos;</li>",
  ];
  const ineligibleMessageAndCriterias =
    gerdauIneligibleMessage +
    '<ul style="text-align: left;">' +
    criteriaList[0] +
    criteriaList[1] +
    criteriaList[2] +
    criteriaList[3] +
    "</ul>";
  const ineligibleSendoff = "Tenha um ótimo dia,";
  const gerdauMessages = {
    elegivel: {
      title: `Olá ${name},`,
      message: `Tenho uma notícia maravilhosa para te contar. Seu cadastro foi aprovado com sucesso e o Reforma que Transforma
                        tem uma solução para transformar sua casa. Em breve nosso time entrará em contato.`,
      sendoff: "Até logo,",
    },
    renda: {
      title: `${name},`,
      message: ineligibleMessageAndCriterias,
      sendoff: ineligibleSendoff,
    },
    regiao: {
      title: `${name},`,
      message: ineligibleMessageAndCriterias,
      sendoff: ineligibleSendoff,
    },
    idade: {
      title: `${name},`,
      message: ineligibleMessageAndCriterias,
      sendoff: ineligibleSendoff,
    },
    produto: {
      title: `${name},`,
      message: ineligibleMessageAndCriterias,
      sendoff: ineligibleSendoff,
    },
  };
  const partnerMessages = {
    ...vivendaMessages,
    elegivel: {
      title: `Olá ${name},`,
      message: `Tenho uma notícia maravilhosa para te contar. 
            Seu cadastro foi aprovado com sucesso e temos uma solução para a reforma dos seus sonhos! Em breve, um representante da empresa Nova Vivenda, de quem somos parceiros de negócio, entrará em contato com você!`,
      sendoff: "Até logo!",
    },
  };

  const messages = {
    gerdau: gerdauMessages,
    partner: partnerMessages,
  };

  return (messages[form] || vivendaMessages)[reason][field];
};

export default getMessage;
