import React from "react";
import StepPagination from "../StepPagination";
import {FormControl, FormGroup, TextField} from "@material-ui/core";
import InputMask from 'react-input-mask';
import '../step.css'

const RefitLeadContactStep = ({values, nextStep, prevStep, handleChange, errors, validation, updateErrors}) => {

    return(
        <div>
            <FormGroup className={'contact-step-fields'}>
                <FormControl>
                    <label className={'field-title'}>CELULAR</label>
                    <InputMask
                        mask="(99) 99999-9999"
                        maskChar={''}
                        onChange={handleChange('phone')}
                        value={values.phone}
                    >
                        {() => <TextField
                            error={errors.phone}
                            helperText={errors.phone}
                            label='Com WhatsApp, se possível'
                            type='tel'
                        />}
                    </InputMask>
                </FormControl>
                <FormControl className={'email-field'}>
                    <label className={'field-title'}>EMAIL</label>
                    <TextField
                        error={errors.email}
                        helperText={errors.email}
                        value={values.email}
                        onChange={handleChange('email')}
                        type={'email'}
                    />
                </FormControl>
            </FormGroup>
            <StepPagination prevStep={prevStep} nextStep={nextStep} validation={validation} updateErrors={updateErrors} values={values}/>
        </div>
    )
}

export default RefitLeadContactStep;
