import { Grid } from "@material-ui/core";
import { Component } from "react";
import RecoverZipcodeAddress from "../../Services/VivendaCore/RecoverZipcodeAddress";
import SubmitRefitLead from "../../Services/VivendaCore/SubmitRefitLead";
import RefitLeadSteps from "../FormSteps/RefitLeadSteps";
import RefitLeadFormMessage from "../RefitLeadFormMessage";
import getMessage from "./final-messages";
import "./index.css";
import { ELIGIBLE_PAGE } from "../../utils/constants";

export default class RefitLeadsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      name: "",
      email: "",
      phone: "",
      tax_id: "",
      zipcode: "",
      state: "",
      city: "",
      neighborhood: "",
      address: "",
      complement: "",
      address_number: "",
      ibgeCode: "",
      isGerdauCollaborator: "",
      privacyPolicyRead: false,
      income: "",
      birthdate: "",
      origin: "",
      errors: {},
      resultTitle: "",
      resultMsg: "",
      resultSendoff: "",
      isLoadingAddress: false,
      product: "REFORMA",
      isEligible: null,
      companyId: null,
      buyWinText: "",
      firstContact: "",
    };
    this.outsideForm = false;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const type = urlParams.get("type");
    this.form = urlParams.get("form");

    const companyId = Number(urlParams.get("companyId"));
    if (companyId) {
      this.companyId = companyId;
      this.form = "partner";
    }

    if (type === "outside") {
      this.outsideForm = true;
    }
    this.handleChange = this.handleChange.bind(this);
  }

  nextStep = (stepNumber = null) => {
    if (!stepNumber) {
      const { step } = this.state;
      this.setState({ step: step + 1 });
    } else {
      this.setState({ step: stepNumber });
    }
  };

  prevStep = (stepNumber = null) => {
    if (!stepNumber) {
      const { step } = this.state;
      this.setState({ step: step - 1 });
    } else {
      this.setState({ step: stepNumber });
    }
  };

  returnToFirstStep = () => {
    this.setState({
      step: 1,
      name: "",
      email: "",
      phone: "",
      tax_id: "",
      zipcode: "",
      state: "",
      city: "",
      neighborhood: "",
      address: "",
      address_number: "",
      isGerdauCollaborator: false,
      privacyPolicyRead: false,
      income: "",
      product: "REFORMA",
      birthdate: "",
      origin: "",
      errors: {},
      resultTitle: "",
      resultMsg: "",
      resultSendoff: "",
      isLoadingAddress: false,
      isEligible: null,
      buyWinText: "",
      firstContact: "",
    });
  };

  getLeadOrigin() {
    return this.state.origin !== "" ? this.state.origin : "FORMULARIO_VIVENDA";
  }

  gtag_report_conversion_lead_eligible() {
    window.gtag("event", "conversion", {
      send_to: "AW-389700561/CNQPCISbgJACENG36bkB",
    });
    return false;
  }

  gtag_report_conversion_lead_not_eligible() {
    window.gtag("event", "conversion", {
      send_to: "AW-389700561/00rZCLCLo5ACENG36bkB",
    });
    return false;
  }

  submit = (values) => {
    SubmitRefitLead(values)
      .then((elegibilityResult) => {
        const url = new URL(window.location);

        let r;
        if (!elegibilityResult.incomeEligibility.value) {
          r = "renda";
        } else if (!elegibilityResult.locationEligibility.value) {
          r = "regiao";
        } else if (!elegibilityResult.ageEligibility.value) {
          r = "idade";
        } else if (!elegibilityResult.productEligibility.value) {
          r = "produto";
        } else {
          r = "elegivel";
        }

        if (r === "elegivel") {
          this.setState({
            resultTitle: getMessage(
              this.state.name.split(" ")[0],
              this.form,
              r,
              "title"
            ),
            resultMsg: getMessage(
              this.state.name.split(" ")[0],
              this.form,
              r,
              "message"
            ),
            resultSendoff: getMessage(
              this.state.name.split(" ")[0],
              this.form,
              r,
              "sendoff"
            ),
            isEligible: true,
          });
          url.searchParams.set("result", "elegivel");
          window.history.pushState(null, null, url.toString());
          this.gtag_report_conversion_lead_eligible();
          if (window.parent) {
            window.parent.location.href = ELIGIBLE_PAGE;
          } else {
            window.location.href = ELIGIBLE_PAGE;
          }
        } else if (r === "renda") {
          this.setState({
            resultTitle: getMessage(
              this.state.name.split(" ")[0],
              this.form,
              r,
              "title"
            ),
            resultMsg: getMessage(
              this.state.name.split(" ")[0],
              this.form,
              r,
              "message"
            ),
            resultSendoff: getMessage(
              this.state.name.split(" ")[0],
              this.form,
              r,
              "sendoff"
            ),
            isEligible: false,
          });
          url.searchParams.set("result", "inelegivel-renda");
          window.history.pushState(null, null, url.toString());
          this.gtag_report_conversion_lead_not_eligible();
        } else if (r === "regiao") {
          this.setState({
            resultTitle: getMessage(
              this.state.name.split(" ")[0],
              this.form,
              r,
              "title"
            ),
            resultMsg: getMessage(
              this.state.name.split(" ")[0],
              this.form,
              r,
              "message"
            ),
            resultSendoff: getMessage(
              this.state.name.split(" ")[0],
              this.form,
              r,
              "sendoff"
            ),
            isEligible: false,
          });
          url.searchParams.set("result", "inelegivel-regiao");
          window.history.pushState(null, null, url.toString());
          this.gtag_report_conversion_lead_not_eligible();
        } else if (r === "idade") {
          this.setState({
            resultTitle: getMessage(
              this.state.name.split(" ")[0],
              this.form,
              r,
              "title"
            ),
            resultMsg: getMessage(
              this.state.name.split(" ")[0],
              this.form,
              r,
              "message"
            ),
            resultSendoff: getMessage(
              this.state.name.split(" ")[0],
              this.form,
              r,
              "sendoff"
            ),
            isEligible: false,
          });
          url.searchParams.set("result", "inelegivel-idade");
          window.history.pushState(null, null, url.toString());
          this.gtag_report_conversion_lead_not_eligible();
        } else if (r === "produto") {
          this.setState({
            resultTitle: getMessage(
              this.state.name.split(" ")[0],
              this.form,
              r,
              "title"
            ),
            resultMsg: getMessage(
              this.state.name.split(" ")[0],
              this.form,
              r,
              "message"
            ),
            resultSendoff: getMessage(
              this.state.name.split(" ")[0],
              this.form,
              r,
              "sendoff"
            ),
            isEligible: false,
          });
          url.searchParams.set("result", "inelegivel-produto");
          window.history.pushState(null, null, url.toString());
          this.gtag_report_conversion_lead_not_eligible();
        }
      })
      .catch(function (error) {
        alert(
          "Ops, não podemos seguir com o seu cadastro. Se já houver um projeto ativo no sistema para você, você receberá uma mensagem automática no seu celular com informações sobre o projeto atual. Obrigada, Vivenda"
        );
      });
    this.nextStep();
  };

  recoverZipcodeAddress(value) {
    const zipcode = value.replace(" ", "").replace("-", "");
    if (zipcode.length === 8) {
      this.setState({ isLoadingAddress: true });
      RecoverZipcodeAddress(zipcode).then((r) => {
        const { city, state, neighborhood, address, ibgeCode } = r;
        this.setState({
          state: state,
          city: city,
          neighborhood: neighborhood,
          address: address,
          ibgeCode,
        });
        this.setState({ isLoadingAddress: false });
      });
    }
  }

  handleChange = (input) => (e) => {
    if (input === "zipcode") {
      this.recoverZipcodeAddress(e.target.value);
    }
    if (input === "isGerdauCollaborator") {
      if (e.target.value === "yes") {
        this.setState({ [input]: true });
      } else {
        this.setState({ [input]: false });
      }
    } else {
      this.setState({
        [input]:
          input === "privacyPolicyRead" ? e.target.checked : e.target.value,
      });
    }
  };

  updateErrors = (errors) => {
    this.setState({ errors: errors });
  };

  getFormClass() {
    return this.form === "gerdau"
      ? "background-image-gerdau"
      : this.form === "compre_ganhe"
      ? "background-image-world-cup"
      : "background-image-vivenda";
  }

  render() {
    const {
      name,
      zipcode,
      birthdate,
      phone,
      email,
      tax_id,
      state,
      city,
      neighborhood,
      address,
      complement,
      address_number,
      income,
      origin,
      isGerdauCollaborator,
      privacyPolicyRead,
      product,
      buyWinText,
      ibgeCode,
      firstContact,
    } = this.state;
    const values = {
      name,
      zipcode,
      birthdate,
      phone,
      email,
      tax_id,
      state,
      city,
      neighborhood,
      address,
      complement,
      address_number,
      income,
      origin,
      isGerdauCollaborator,
      privacyPolicyRead,
      product,
      buyWinText,
      ibgeCode,
      firstContact,
    };
    values.form = this.form;
    values.companyId = this.companyId;
    const { errors } = this.state;
    const { resultTitle, resultMsg, resultSendoff } = this.state;
    const { step } = this.state;
    const { isLoadingAddress } = this.state;
    const { isEligible } = this.state;
    return (
      <div className={this.getFormClass()}>
        <div className={"form-box"}>
          <Grid container className={"form"}>
            <Grid sm={6} xs={12}>
              <RefitLeadFormMessage
                name={this.state.name?.split(" ")[0].toUpperCase()}
                step={this.state.step}
                outsideForm={this.outsideForm}
                form={this.form}
              />
            </Grid>
            <Grid sm={6} xs={12} className={"step-container"}>
              <RefitLeadSteps
                updateErrors={this.updateErrors}
                handleChange={this.handleChange}
                outsideForm={this.outsideForm}
                step={step}
                values={values}
                nextStep={this.nextStep}
                submit={this.submit}
                prevStep={this.prevStep}
                errors={errors}
                resultMsg={resultMsg}
                resultTitle={resultTitle}
                resultSendoff={resultSendoff}
                isLoadingAddress={isLoadingAddress}
                isEligible={isEligible}
                returnToFirstStep={this.returnToFirstStep}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
