import React from "react";
import {Button} from "@material-ui/core";
import '../step.css';

const StepPagination = ({disableNextButton = false,prevStepNumber = null ,nextStepNumber = null,nextStep, prevStep, validation, submit, values, updateErrors, returnToFirstStep}) => {

    const Continue = e => {
        e.preventDefault()
        const errors = validation(values)
        updateErrors(errors)
        if(JSON.stringify(errors) === JSON.stringify({})) {
            nextStep(nextStepNumber)
        }
    }

    const Previous = e => {
        e.preventDefault()
        prevStep(prevStepNumber)
    }
    
    const Submit = e => {
        e.preventDefault()
        const errors = validation(values)
        updateErrors(errors)
        if (JSON.stringify(errors) === JSON.stringify({})) {
            submit(values)
        }
    }

    const gerdauStyle = () =>{
        const urlParams = new URLSearchParams(window.location.search);
        const form = urlParams.get('form')
        return form !== 'gerdau' ? '' : '-gerdau'
    }

    const ReturnToFirstStep = e => {
      e.preventDefault()
      returnToFirstStep()
    }

    const nextButtonClassName = () => {
      return  disableNextButton ? 'disabled-next-button' : 'next-prev-btn' + gerdauStyle() 
    }

    return(
        <div className={'pagination-buttons'}>
            { prevStep != null && <Button className={'next-prev-btn' + gerdauStyle()} variant="contained" onClick={ Previous }>Voltar</Button> }
            { nextStep != null && <Button disabled={disableNextButton} className={nextButtonClassName()} variant="contained" onClick={ Continue }>Avançar</Button> }
            { submit != null && <Button className={'submit-btn' + gerdauStyle()} variant="contained" onClick={ Submit }>Enviar</Button> }
            { returnToFirstStep != null && <Button className={'return-btn' + gerdauStyle()} variant="contained" onClick={ ReturnToFirstStep }>Retornar</Button> }
        </div>
    )
}

export default StepPagination;
