import React from "react";
import StepPagination from "../StepPagination";
import { FormControl, FormGroup, TextField } from "@material-ui/core";
import "../step.css";

const RefitLeadBuyWinStep = ({
  submit,
  validation,
  values,
  updateErrors,
  handleChange,
  errors,
  prevStep,
}) => {
  return (
    <div>
      <FormGroup className={"buy-win-step-fields"}>
        <FormControl>
          <label className={"field-title"}>
            Quais prêmios você poderá ganhar ao participar da promoção SUA CASA
            LINDA PARA TORCER PELO BRASIL?
          </label>
          <br></br>
          <TextField
            label="Escreva aqui..."
            error={errors.buyWinText}
            onChange={handleChange("buyWinText")}
            value={values.buyWinText}
            helperText={errors.buyWinText}
            multiline
            maxRows={5}
          />
        </FormControl>
      </FormGroup>
      <StepPagination
        submit={submit}
        prevStep={prevStep}
        validation={validation}
        values={values}
        updateErrors={updateErrors}
      />
    </div>
  );
};

export default RefitLeadBuyWinStep;
