import axios from "axios";
import FORM_SUBMIT_URL from "./VivendaCoreURL";

const addressTypes = {
  aeroporto: "Aeroporto",
  alameda: "Alameda",
  area: "Área",
  avenida: "Avenida",
  beco: "Beco",
  caminho: "Caminho",
  campo: "Campo",
  chacara: "Chacara",
  colonia: "Colônia",
  condominio: "Condomínio",
  conjunto: "Conjunto",
  distrito: "Distrito",
  esplanada: "Esplanada",
  estacao: "Estação",
  estrada: "Estrada",
  etapa: "Etapa",
  favela: "Favela",
  fazenda: "Fazenda",
  feira: "Feira",
  jardim: "Jardim",
  ladeira: "Ladeira",
  lago: "Lago",
  lagoa: "Lagoa",
  largo: "Largo",
  loteamento: "Loteamento",
  morro: "Morro",
  nucleo: "Núcleo",
  parque: "Parque",
  passarela: "Passarela",
  patio: "Patio",
  praca: "Praça",
  quadra: "Quadra",
  recanto: "Recanto",
  residencial: "Residencial",
  rodovia: "Rodovia",
  rua: "Rua",
  setor: "Setor",
  servidao: "Servidão",
  sitio: "Sítio",
  travessa: "Travessa",
  trecho: "Trecho",
  trevo: "Trevo",
  vale: "Vale",
  vereda: "Vereda",
  via: "Via",
  viaduto: "Viaduto",
  viela: "Viela",
  vila: "Vila",
};

const removeAddressTypeFromAddress = (address) => {
  return address.substr(address.indexOf(" ") + 1);
};

const getAddressType = (address) => {
  const type = address
    .split(" ")[0]
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  return addressTypes[type] ? addressTypes[type] : "outro";
};

const getAddress = (values) => {
  return getAddressType(values.address) === "outro"
    ? values.address
    : removeAddressTypeFromAddress(values.address);
};

const getLeadOrigin = (values) => {
  return values.origin !== ""
    ? values.origin
    : isPartnerForm(values)
    ? "FORMULARIO_NC"
    : getFormSource();
};

const getFormSource = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const form = urlParams.get("form");
  return form === "gerdau"
    ? "FORMULARIO_RQT"
    : form === "compre_ganhe"
    ? "FORMULARIO_COMPRE_GANHE"
    : "FORMULARIO_VIVENDA";
};

const isPartnerForm = (values) => {
  return !!values.companyId;
};

const getPersonData = (values) => {
  const [day, month, year] = values.birthdate.split("/");
  return {
    name: values.name.trim(),
    birthday: `${year}-${month}-${day}`,
    familyIncomeRange: values.income,
    email: values.email,
    mobile: values.phone,
    cpf: values.tax_id,
    origin: getLeadOrigin(values),
  };
};

const getAddressData = (values) => {
  return {
    address: values.address,
    number: values.address_number,
    district: values.neighborhood,
    city: values.city,
    zipcode: values.zipcode,
    state: values.state,
    ibgeCode: values.ibgeCode,
    complement: values.complement,
  };
};

const getPersonIdentification = (values) => {
  return {
    number: values.tax_id,
    identification_type: "CPF",
  };
};

const getPersonPhoneContact = (values) => {
  return {
    contact_type: "cell_phone",
    contact: values.phone,
    whatsapp: true,
    preferred: false,
  };
};

const getPersonEmailContact = (values) => {
  return {
    contact_type: "email",
    contact: values.email,
    whatsapp: false,
    preferred: false,
  };
};

export default async function SubmitRefitLead(values) {
  const address = getAddressData(values);

  let formData = {
    person: getPersonData(values),
    personAddress: { ...address },
    gerdauProject: true,
    socialCauseCompanyId: values.companyId,
    productType: values.product,
    origin: getLeadOrigin(values),
    subsidizedProject: false,
    firstContact: values.firstContact,
  };

  return await axios
    .post(`${FORM_SUBMIT_URL}`, formData, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_VC2_API_KEY,
      },
    })
    .then(function (response) {
      return response.data;
    });
}
