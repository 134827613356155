import {
  FormControl,
  FormGroup,
  FormHelperText,
  MenuItem,
  Select,
} from "@material-ui/core";
import React from "react";
import "../step.css";
import StepPagination from "../StepPagination";

const RefitLeadOriginStep = ({
  submit,
  validation,
  values,
  updateErrors,
  handleChange,
  errors,
  prevStep,
}) => {
  const origins = [
    {
      text: "Campanha IG",
      value: "CAMPANHA_INSTAGRAM",
    },
    {
      text: "Campanha FB",
      value: "CAMPANHA_FACEBOOK",
    },
    {
      text: "Orgânico IG",
      value: "ORGANICO_INSTAGRAM",
    },
    {
      text: "Orgânico FB",
      value: "ORGANICO_FACEBOOK",
    },
    {
      text: "WhatsApp",
      value: "WHATSAPP",
    },
    {
      text: "Telefone",
      value: "TELEFONE",
    },
    {
      text: "E-mail",
      value: "EMAIL",
    },
    {
      text: "Orgânico Campanha Compre e Ganhe",
      value: "ORGANICO_COMPRE_GANHE",
    },
  ];
  return (
    <div>
      <FormGroup className={"origin-step-fields"}>
        <FormControl error={errors.origin}>
          <label className={"field-title"}>CANAL DE ENTRADA DO LEAD</label>
          <Select
            value={values.origin}
            error={errors.origin}
            onChange={handleChange("origin")}
          >
            {origins.map((origin) => {
              return <MenuItem value={origin.value}>{origin.text}</MenuItem>;
            })}
          </Select>
          <FormHelperText>{errors.origin}</FormHelperText>
        </FormControl>
      </FormGroup>
      <StepPagination
        submit={submit}
        prevStep={prevStep}
        validation={validation}
        values={values}
        updateErrors={updateErrors}
      />
    </div>
  );
};

export default RefitLeadOriginStep;
