import React from "react";
import './index.css'

const HelperText = ({ text }) => (
    <div className={'helperText'}>
        <div>
            <img src={getQuestionIcon()} alt="question mark icon"/>
        </div>
        <div>
            <p className={'title'}>Por que estamos pedindo isso?</p>
            <p>{text}</p>
        </div>
    </div>
);

const getQuestionIcon = () =>{
    const urlParams = new URLSearchParams(window.location.search);
    const form = urlParams.get('form')
    return form !== 'gerdau' ? 'question.png' : 'question-gerdau.png'
}

export default HelperText;
