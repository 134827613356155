import React from "react";
import StepPagination from "../StepPagination";
import {
  FormControlLabel,
  FormGroup,
  FormControl,
  Radio,
  RadioGroup,
  FormHelperText,
} from "@material-ui/core";
import "../step.css";

const RefitLeadGerdauCollaboratorStep = ({
  values,
  nextStep,
  prevStep,
  handleChange,
  errors,
  validation,
  updateErrors,
}) => {
  const mustDisableNextButton = () => {
    return !(
      values.isGerdauCollaborator === "" || !values.isGerdauCollaborator
    );
  };

  const getGerdauCollaboratorMessage = () => {
    return `${values.name}, você ou alguém da sua residência é colaborador(a), ou possui vínculo formal com a Gerdau atualmente?`;
  };

  const getValue = () => {
    return values.isGerdauCollaborator === ""
      ? ""
      : values.isGerdauCollaborator
      ? "yes"
      : "no";
  };

  return (
    <div>
      <FormGroup className={"gerdauColabFormGroup"}>
        <label className={"field-title"}>
          {getGerdauCollaboratorMessage()}
        </label>
        <FormControl error={errors.isGerdauCollaborator}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={getValue()}
            onChange={handleChange("isGerdauCollaborator")}
            className={"gerdauColab"}
          >
            <FormControlLabel
              error={errors.isGerdauCollaborator}
              className={"gerdauColabLabel"}
              value="yes"
              control={<Radio />}
              label="Sim"
            />
            <FormControlLabel
              error={errors.isGerdauCollaborator}
              className={"gerdauColabLabel"}
              value="no"
              control={<Radio />}
              label="Não"
            />
          </RadioGroup>
          <FormHelperText>{errors.isGerdauCollaborator}</FormHelperText>
        </FormControl>
      </FormGroup>
      {mustDisableNextButton() && (
        <div className={"gerdauColabText"}>
          O Reforma que transforma possui uma solução especial para
          colaboradores com vínculo empregatício com a Gerdau. Se você ou alguém
          da sua residência se encontra na posição de colaborador da Gerdau,
          procure o RH de sua unidade para maiores informações de como reformar
          com o Reforma que transforma.
        </div>
      )}
      {!values.isGerdauCollaborator && values.isGerdauCollaborator !== "" && (
        <div className={"gerdauColabText"}>
          Ok! Então vamos continuar seu cadastro por aqui mesmo! É só clicar em
          "Avançar".
        </div>
      )}
      <StepPagination
        disableNextButton={mustDisableNextButton()}
        prevStepNumber={1}
        nextStepNumber={2}
        prevStep={prevStep}
        nextStep={nextStep}
        validation={validation}
        updateErrors={updateErrors}
        values={values}
      />
    </div>
  );
};

export default RefitLeadGerdauCollaboratorStep;
