import axios from "axios";

export default async function RecoverZipcodeAddress(zipcode) {
  return await axios
    .get(`https://viacep.com.br/ws/${zipcode}/json`)
    .then((response) => {
      return {
        city: response.data.localidade,
        neighborhood: response.data.bairro,
        state: response.data.uf,
        address: response.data.logradouro,
        ibgeCode: response.data.ibge,
      };
    })
    .catch((err) => {
      return err?.response;
    });
}
