import React from "react";
import StepPagination from "../StepPagination";
import {
  FormControl,
  FormGroup,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import Input from "@material-ui/core/Input";
import InputMask from "react-input-mask";

const RefitLeadAddressStep = ({
  values,
  nextStep,
  prevStep,
  errors,
  handleChange,
  validation,
  updateErrors,
  isLoadingAddress,
}) => {
  const ufs = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MS",
    "MT",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
  ];

  return (
    <div>
      <FormGroup className={"address-step-fields"}>
        <FormControl>
          <label className={"field-title"}>CEP</label>
          <InputMask
            mask="99999-999"
            maskChar={""}
            onChange={handleChange("zipcode")}
            value={values.zipcode}
          >
            {() => (
              <TextField
                error={errors.zipcode}
                helperText={errors.zipcode}
                type="tel"
              />
            )}
          </InputMask>
        </FormControl>
        <FormControl error={errors.state}>
          <label className={"field-title"}>ESTADO</label>
          {isLoadingAddress && <div className={"field-loading"} />}
          <Select
            value={values.state}
            error={errors.state}
            label="Estado"
            onChange={handleChange("state")}
            disabled={true}
          >
            {ufs.map((uf) => {
              return <MenuItem value={uf}>{uf}</MenuItem>;
            })}
          </Select>
          <FormHelperText>{errors.state}</FormHelperText>
        </FormControl>
        <FormControl>
          <label className={"field-title"}>CIDADE</label>
          {isLoadingAddress && <div className={"field-loading"} />}
          <TextField
            value={values.city}
            error={errors.city}
            helperText={errors.city}
            onChange={handleChange("city")}
            disabled={true}
          />
        </FormControl>
        <FormControl>
          <label className={"field-title"}>BAIRRO</label>
          {isLoadingAddress && <div className={"field-loading"} />}
          <TextField
            value={values.neighborhood}
            error={errors.neighborhood}
            helperText={errors.neighborhood}
            onChange={handleChange("neighborhood")}
            disabled={isLoadingAddress}
          />
        </FormControl>
        <FormControl>
          <label className={"field-title"}>ENDEREÇO</label>
          {isLoadingAddress && <div className={"field-loading"} />}
          <TextField
            value={values.address}
            error={errors.address}
            helperText={errors.address}
            onChange={handleChange("address")}
            disabled={isLoadingAddress}
          />
        </FormControl>
        <FormControl>
          <label className={"field-title"}>NÚMERO</label>
          <Input
            type="number"
            value={values.address_number}
            error={errors.address_number}
            helperText={errors.address_number}
            onChange={handleChange("address_number")}
          />
        </FormControl>
        <FormControl>
          <label className={"field-title"}>COMPLEMENTO</label>
          {isLoadingAddress && <div className={"field-loading"} />}
          <TextField
            value={values.complement}
            onChange={handleChange("complement")}
            disabled={isLoadingAddress}
          />
        </FormControl>
      </FormGroup>
      <StepPagination
        nextStep={nextStep}
        prevStep={prevStep}
        validation={validation}
        updateErrors={updateErrors}
        values={values}
      />
    </div>
  );
};

export default RefitLeadAddressStep;
