import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  MenuItem,
  Select,
} from "@material-ui/core";
import React from "react";
import HelperText from "../HelperText";
import StepPagination from "../StepPagination";

const RefitLeadFirstContactStep = ({
  values,
  prevStep,
  handleChange,
  validation,
  errors,
  submit,
  updateErrors,
  nextStep,
}) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const form = urlParams.get("form");
  const isRqt = form === "gerdau";
  return (
    <div>
      <FormGroup className={"meet-step-fields"}>
        <FormControl error={errors.firstContact}>
          <label className={"field-title"}>
            ONDE VOCÊ CONHECEU{" "}
            {isRqt ? "O RERFOMA QUE TRANSFORMA" : "A VIVENDA"}?
          </label>
          <Select
            className={"firstContact-select"}
            value={values.firstContact}
            error={errors.firstContact}
            label="Renda"
            onChange={handleChange("firstContact")}
          >
            <MenuItem value="Instagram">Instagram</MenuItem>
            <MenuItem value="Facebook">Facebook</MenuItem>
            <MenuItem value="Carro de som">Carro de som</MenuItem>
            <MenuItem value="Influenciador local">Influenciador local</MenuItem>
            <MenuItem value="Banner">Banner</MenuItem>
            <MenuItem value="Rádio">Rádio</MenuItem>
            <MenuItem value="Panfleto">Panfleto</MenuItem>
            <MenuItem value="Escritório de reforma">
              Escritório de reforma
            </MenuItem>
            <MenuItem value="Indicação de conhecidos">
              Indicação de conhecidos
            </MenuItem>
          </Select>
          <FormHelperText className={"firstContact-formhelper"}>
            {errors.firstContact}
          </FormHelperText>
        </FormControl>
        <HelperText
          text={
            "Pedimos isso para ajudar na avaliação do seu crédito. Com a renda estimada, conseguimos liberar seu crédito de forma mais rápida e com a garantia que seja um valor que não vai te apertar no fim do mês."
          }
        />
        <div className={"privacyPolicyInfo"}>
          <p>
            Coletamos e mantemos seus dados para executar nossas atividades,
            divulgar nossos serviços e oferecer conteúdos relevantes. Ao clicar
            em “Enviar”, você concorda que seus dados sejam tratados e
            utilizados conforme estabelecido em nossa{" "}
            <span
              className={"privacyPolicyLink"}
              onClick={() =>
                window.open(
                  "https://www.vivenda.io/termo-de-uso-e-politica-de-privacidade",
                  "_blank"
                )
              }
            >
              Política de Privacidade.
            </span>
          </p>
        </div>
        <FormControlLabel
          className={"privacyPolicy"}
          control={
            <Checkbox
              name="lgpd_agreement"
              size="small"
              checked={values.privacyPolicyRead}
              onChange={handleChange("privacyPolicyRead")}
            />
          }
          label={<p>Li e aceito.</p>}
        />
        <FormHelperText className={"customFormError"}>
          {errors.privacyPolicyRead}
        </FormHelperText>
      </FormGroup>
      <StepPagination
        prevStep={prevStep}
        nextStep={nextStep}
        submit={submit}
        validation={validation}
        updateErrors={updateErrors}
        values={values}
      />
    </div>
  );
};

export default RefitLeadFirstContactStep;
