const nameStepValidation = (values) => {
  let errors = {};
  if (!values.name) {
    errors.name = "Obrigatório";
  }
  return errors;
};

const contactStepValidation = (values) => {
  let errors = {};
  if (!values.phone) {
    errors.phone = "Obrigatório";
  } else if (!/^\(\d{2}\)\s\d{4,5}-\d{4}$/i.test(values.phone)) {
    errors.phone = "Celular inválido";
  }
  if (!values.email) {
    errors.email = "Obrigatório";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Endereço de e-mail inválido";
  }
  return errors;
};

const gerdauCollaboratorValidation = (values) => {
  let errors = {};
  if (values.isGerdauCollaborator === "") {
    errors.isGerdauCollaborator = "Preencha uma das opções";
  }
  return errors;
};

const birthdateValidation = (values) => {
  let errors = {};
  if (!values.birthdate) {
    errors.birthdate = "Obrigatório";
  } else if (
    !/^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]\d{4}$/.test(
      values.birthdate
    )
  ) {
    errors.birthdate =
      "Formato da data de nascimento dever seguir o padrão dd/mm/aaaa";
  }
  return errors;
};

const taxIdStepValidation = (values) => {
  let errors = {};
  var validateTaxID = (tax_id) => {
    tax_id = tax_id.replace(/\D/g, "");

    var sum;
    var rest;
    var i;

    sum = 0;
    if (tax_id === "00000000000") return false;

    for (i = 1; i <= 9; i++)
      sum = sum + parseInt(tax_id.substring(i - 1, i)) * (11 - i);
    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(tax_id.substring(9, 10))) return false;

    sum = 0;
    for (i = 1; i <= 10; i++)
      sum = sum + parseInt(tax_id.substring(i - 1, i)) * (12 - i);
    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(tax_id.substring(10, 11))) return false;

    return true;
  };

  if (!values.tax_id) {
    errors.tax_id = "Obrigatório";
  } else if (!/^[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}$/.test(values.tax_id)) {
    errors.tax_id = "Formato do CPF deve seguir o padrão 012.345.678-90";
  } else if (!validateTaxID(values.tax_id)) {
    errors.tax_id = "Número de CPF inválido";
  }
  return errors;
};

const addressValidations = (values) => {
  let errors = {};

  if (!values.state) {
    errors.state = "Obrigatório";
  }

  if (!values.address_number) {
    errors.address_number = "Obrigatório";
  }

  if (!values.city) {
    errors.city = "Obrigatório";
  }

  if (!values.neighborhood) {
    errors.neighborhood = "Obrigatório";
  }

  if (!values.address) {
    errors.address = "Obrigatório";
  }

  if (!values.zipcode) {
    errors.zipcode = "Obrigatório";
  } else if (!/^[0-9]{5}-[0-9]{3}$/i.test(values.zipcode)) {
    errors.zipcode = "Formato do CEP deve seguir o padrão 12345-678";
  }
  return errors;
};

const productValidation = (values) => {
  let errors = {};
  if (!values.product) {
    errors.product = "Obrigatório";
  }
  return errors;
};

const incomeValidation = (values) => {
  let errors = {};
  if (!values.income) {
    errors.income = "Obrigatório";
  }
  return errors;
};

const originValidation = (values) => {
  let errors = {};
  if (!values.origin) {
    errors.origin = "Obrigatório";
  }
  return errors;
};

const firstContactValidations = (values) => {
  let errors = {};
  if (!values.firstContact) {
    errors.firstContact = "Obrigatório";
  }
  if (!values.privacyPolicyRead) {
    errors.privacyPolicyRead = "Preencha todos os campos";
  }
  return errors;
};

const buyWinValidation = (values) => {
  let errors = {};
  if (!values.buyWinText) {
    errors.buyWinText = "Obrigatório";
  }
  return errors;
};

export {
  gerdauCollaboratorValidation,
  incomeValidation,
  nameStepValidation,
  contactStepValidation,
  taxIdStepValidation,
  birthdateValidation,
  addressValidations,
  originValidation,
  productValidation,
  buyWinValidation,
  firstContactValidations,
};
