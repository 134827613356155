import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  MenuItem,
  Select,
} from "@material-ui/core";
import React from "react";
import HelperText from "../HelperText";
import StepPagination from "../StepPagination";

const RefitLeadIncomeStep = ({
  values,
  prevStep,
  handleChange,
  validation,
  errors,
  updateErrors,
  nextStep,
}) => {
  return (
    <div>
      <FormGroup className={"income-step-fields"}>
        <FormControl error={errors.income}>
          <label className={"field-title"}>RENDA</label>
          <Select
            className={"income-select"}
            value={values.income}
            error={errors.income}
            label="Renda"
            onChange={handleChange("income")}
          >
            <MenuItem value="0">Menos de R$ 937,00</MenuItem>
            <MenuItem value="1">Entre R$ 1.101,00 e R$ 2.200,00</MenuItem>
            <MenuItem value="2">Entre R$ 2.201,00 e R$ 3.300,00</MenuItem>
            <MenuItem value="3">Entre R$ 3.301,00 e R$ 4.400,00</MenuItem>
            <MenuItem value="4">Mais de R$ 4.401,00</MenuItem>
          </Select>
          <FormHelperText className={"income-formhelper"}>
            {errors.income}
          </FormHelperText>
        </FormControl>
      </FormGroup>
      <StepPagination
        prevStep={prevStep}
        nextStep={nextStep}
        validation={validation}
        updateErrors={updateErrors}
        values={values}
      />
    </div>
  );
};

export default RefitLeadIncomeStep;
