import RefitLeadNameStep from "../RefitLeadNameStep";
import RefitLeadTaxIdStep from "../RefitLeadTaxIdStep";
import RefitLeadContactStep from "../RefitLeadContactStep";
import RefitLeadBirthDateStep from "../RefitLeadBirthDayStep";
import RefitLeadAddressStep from "../RefitLeadAddressStep";
import RefitLeadIncomeStep from "../RefitLeadIncomeStep";
import RefitLeadResultStep from "../RefitLeadResultStep";
import RefitLeadGerdauCollaboratorStep from "../RefitLeadGerdauCollaboratorStep";
import RefitLeadProductStep from "../RefitLeadProductStep";
import RefitLeadBuyWinStep from "../RefitLeadBuyWinStep";

import {
  addressValidations,
  birthdateValidation,
  contactStepValidation,
  incomeValidation,
  nameStepValidation,
  originValidation,
  taxIdStepValidation,
  gerdauCollaboratorValidation,
  productValidation,
  buyWinValidation,
  firstContactValidations,
} from "../../../Validations/StepsValidations";
import RefitLeadOriginStep from "../RefiLeadOriginStep";
import "../step.css";
import RefitLeadFirstContactStep from ".././RefitLeadFirstContactStep";

const RefitLeadSteps = ({
  values,
  errors,
  step,
  nextStep,
  prevStep,
  submit,
  handleChange,
  updateErrors,
  outsideForm,
  resultTitle,
  resultMsg,
  resultSendoff,
  isLoadingAddress,
  isEligible,
  returnToFirstStep,
}) => {
  const getCurrentStepComponent = () => {
    switch (step) {
      case 1:
        return (
          <RefitLeadNameStep
            values={values}
            errors={errors}
            handleChange={handleChange}
            nextStep={nextStep}
            validation={nameStepValidation}
            updateErrors={updateErrors}
          />
        );
      case 2:
        return (
          <RefitLeadProductStep
            values={values}
            handleChange={handleChange}
            updateErrors={updateErrors}
            validation={productValidation}
            errors={errors}
            prevStep={prevStep}
            nextStep={nextStep}
          />
        );
      case 3:
        return (
          <RefitLeadContactStep
            values={values}
            handleChange={handleChange}
            nextStep={nextStep}
            prevStep={prevStep}
            errors={errors}
            validation={contactStepValidation}
            updateErrors={updateErrors}
          />
        );
      case 4:
        return (
          <RefitLeadTaxIdStep
            values={values}
            handleChange={handleChange}
            nextStep={nextStep}
            prevStep={prevStep}
            errors={errors}
            validation={taxIdStepValidation}
            updateErrors={updateErrors}
          />
        );
      case 5:
        return (
          <RefitLeadBirthDateStep
            values={values}
            handleChange={handleChange}
            nextStep={nextStep}
            prevStep={prevStep}
            errors={errors}
            validation={birthdateValidation}
            updateErrors={updateErrors}
          />
        );
      case 6:
        return (
          <RefitLeadAddressStep
            values={values}
            handleChange={handleChange}
            errors={errors}
            nextStep={nextStep}
            prevStep={prevStep}
            validation={addressValidations}
            updateErrors={updateErrors}
            isLoadingAddress={isLoadingAddress}
          />
        );
      case 7:
        return (
          <RefitLeadIncomeStep
            values={values}
            validation={incomeValidation}
            handleChange={handleChange}
            errors={errors}
            nextStep={nextStep}
            prevStep={prevStep}
            updateErrors={updateErrors}
          />
        );
      case 8:
        return (
          <RefitLeadFirstContactStep
            values={values}
            validation={firstContactValidations}
            handleChange={handleChange}
            errors={errors}
            submit={
              outsideForm || values.form === "compre_ganhe" ? null : submit
            }
            nextStep={
              outsideForm || values.form === "compre_ganhe" ? nextStep : null
            }
            prevStep={prevStep}
            updateErrors={updateErrors}
          />
        );
      case 9:
        return outsideForm ? (
          <RefitLeadOriginStep
            values={values}
            handleChange={handleChange}
            updateErrors={updateErrors}
            validation={originValidation}
            errors={errors}
            submit={submit}
            prevStep={prevStep}
          />
        ) : values.form === "compre_ganhe" ? (
          <RefitLeadBuyWinStep
            values={values}
            handleChange={handleChange}
            updateErrors={updateErrors}
            validation={buyWinValidation}
            errors={errors}
            submit={submit}
            prevStep={prevStep}
          />
        ) : (
          <RefitLeadResultStep
            returnToFirstStep={returnToFirstStep}
            resultTitle={resultTitle}
            resultMsg={resultMsg}
            resultSendoff={resultSendoff}
            isEligible={isEligible}
          />
        );
      case 10:
        return outsideForm ? (
          <RefitLeadResultStep
            returnToFirstStep={returnToFirstStep}
            resultTitle={resultTitle}
            resultMsg={resultMsg}
            resultSendoff={resultSendoff}
            isEligible={isEligible}
          />
        ) : values.form === "compre_ganhe" ? (
          <RefitLeadResultStep
            returnToFirstStep={returnToFirstStep}
            resultTitle={resultTitle}
            resultMsg={resultMsg}
            resultSendoff={resultSendoff}
            isEligible={isEligible}
          />
        ) : (
          ""
        );
      case 11:
        return (
          <RefitLeadGerdauCollaboratorStep
            values={values}
            handleChange={handleChange}
            nextStep={nextStep}
            prevStep={prevStep}
            errors={errors}
            validation={gerdauCollaboratorValidation}
            updateErrors={updateErrors}
          />
        );
      default:
        return "";
    }
  };

  const getPaginationText = () => {
    const maxStep = outsideForm ? 9 : 8;
    return step > maxStep ? "" : `PASSO ${step} DE ${maxStep}`;
  };

  return (
    <div className={"step-box"}>
      <div className={"steps-pagination-text"}>{getPaginationText()}</div>
      <div className={"step-fields"}>{getCurrentStepComponent()}</div>
    </div>
  );
};

export default RefitLeadSteps;
