import { Component } from "react";
import "./index.css";

export default class RefitLeadFormMessage extends Component {
  getFormMessage = () => {
    switch (this.props.step) {
      case 1:
        return "QUEREMOS TE CONHECER MELHOR!";
      case 2:
        return `OIÊ ${this.props.name}! CONTA SEU SONHO PRA GENTE!`;
      case 3:
        return `AGORA PRECISAMOS DO SEU CONTATO:`;
      case 4:
        return `${this.props.name}, SÓ MAIS ALGUNS DADOS!`;
      case 5:
        return "VAMOS CANTAR PARABÉNS COM VOCÊ!";
      case 6:
        return `QUAL O ENDEREÇO DA REFORMA, ${this.props.name}?`;
      case 7:
        return "ESTAMOS QUASE LÁ!";
      case 8:
        return "";
      case 9:
        return this.props.outsideForm
          ? ""
          : this.props.form === "compre_ganhe"
          ? "BORA DEIXAR SUA CASA LINDA PARA TORCER PELO BRASIL?"
          : "CADASTRO FINALIZADO ;)";
      case 10:
        return this.props.outsideForm || this.props.form === "compre_ganhe"
          ? "CADASTRO FINALIZADO ;)"
          : "";
      default:
        return "";
    }
  };

  render() {
    return (
      <div className={"form-message"}>
        <h1>{this.getFormMessage()}</h1>
      </div>
    );
  }
}
