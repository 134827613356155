import React from "react";
import StepPagination from "../StepPagination";
import {FormControl, FormGroup, TextField} from "@material-ui/core";
import InputMask from "react-input-mask";
import HelperText from "../HelperText";

const RefitLeadTaxIdStep = ({values, nextStep, prevStep, validation, errors, handleChange, updateErrors}) => {
    return(
        <div>
            <FormGroup className={'tax-id-step-fields'}>
                <FormControl>
                    <label className={'field-title'}>CPF</label>
                    <br></br>
                    <InputMask
                        mask="999.999.999-99"
                        maskChar={''}
                        onChange={handleChange('tax_id')}
                        value={values.tax_id}
                    >
                        {() => <TextField
                            error={errors.tax_id}
                            helperText={errors.tax_id}
                            type='tel'
                        />}
                    </InputMask>
                </FormControl>
                <HelperText text="Pedimos isso para ajudar na avaliação do seu crédito :)" />
            </FormGroup>
            <StepPagination prevStep={prevStep} nextStep={nextStep} validation={validation} updateErrors={updateErrors} values={values}/>
        </div>
    )
}

export default RefitLeadTaxIdStep;
