import React from "react";
import styles from "./index.module.css";
import StepPagination from "../StepPagination";

const RefitLeadResultStep = ({
  resultTitle,
  resultMsg,
  resultSendoff,
  isEligible,
  returnToFirstStep,
}) => {
  return resultTitle ? (
    <div className={"result-step"}>
      {isEligible && <img src={"heart.png"} alt={"heart"} />}
      <h1>{resultTitle}</h1>
      <p dangerouslySetInnerHTML={{ __html: resultMsg }}></p>
      <p>{resultSendoff}</p>
      <b>{getReformProgramName()}</b>
      <StepPagination returnToFirstStep={returnToFirstStep} />
    </div>
  ) : (
    <div className={styles.loading} />
  );
};

const getReformProgramName = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const form = urlParams.get("form");
  return form !== "gerdau" ? "Nova Vivenda" : "Equipe Reforma que Transforma";
};

export default RefitLeadResultStep;
